import React from "react";
import { motion } from "framer-motion";
import PropTypes from "prop-types";
import PageHeader from "../components/PageHeader";
import YoutubeEmbed from "../components/YoutubeEmbed";

const embedItems = [
  {
    id: 1,
    text: "Miw5NyS-1J4?si=R9Pcsw2gS64emA_p",
    title:
      "Living healthy with Janiba ginger e2 - Rich Carrot Juice for a glowing skin",
  },
  {
    id: 2,
    text: "FLZouNtfksM?si=3TU1sVc4OclTevna",
    title:
      "Living healthy with Janiba ginger e1 - Beetroot juice for blood pressure management",
  },
  {
    id: 3,
    text: "vjwzIyKhz0g?si=DI_auchirV556sUu",
    title: "Introducing Pick and Mix from Janiba Ginger",
  },
  {
    id: 4,
    text: "YSLvOvl5duc?si=2jpTbIlj4V2BFb_H",
    title: "Bagged cleaned Hibiscus Sabdariffa ",
  },
  {
    id: 5,
    text: "iAxYLfafieo?si=ratcA9zQrk4eSCZa",
    title: "Ginger Bagging",
  },
  {
    id: 6,
    text: "i7JD3FmGE28?si=SQQVyUHT2Fg8whuZ",
    title: "Ginger Cleaning",
  },
];

function Videos({ embedId }) {
  return (
    <>
      <div className="w-full p-12 md:p-6">
        <div className="shadow-lg p-12 md:p-6">
          <PageHeader title="Videos" />
          <div className="grid grid-cols-8 lg:gap-8 gap-16 px-32 lg:px-12 sm:px-8 text-center">
            {embedItems.map((item, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 100 }}
                whileInView={{
                  opacity: 1,
                  y: 0,
                  transition: { delay: 0.5, duration: 0.5 },
                }}
                viewport={{ once: true }}
                className="col-span-4 sm:col-span-8 space-y-4 shadow-lg py-3"
              >
                <YoutubeEmbed embedId={item.text} />
                <div className="p-2">
                  <h2 className="text-md text-darkgray">{item.title}</h2>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

Videos.propTypes = {
  embedId: PropTypes.string.isRequired,
};

export default Videos;

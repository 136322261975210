import React from "react";
import Nav from "./nav/Nav";
import Footer from "./footer/Footer";
import Sidebar from "./sidebar/SideAd";

function Layout({ children }) {
  return (
    <div>
      <Nav />
      <main className="flex">{children}</main>
      <Footer />
    </div>
  );
}

export default Layout;

import React from "react";
import PriceList from "../../images/pricelist.jpeg";

function SidePriceList() {
  return (
    <div className="p-4 md:p-2">
      <img
        src={PriceList}
        alt="ad"
        className="h-[500px] lg:h-[300px] sm:h-auto rounded-2xl w-full block"
      />
    </div>
  );
}

export default SidePriceList;

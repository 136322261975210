import React from "react";
import {
  FaAt,
  FaEnvelopeOpen,
  FaMapMarkedAlt,
  FaMapMarker,
  FaMobileAlt,
  FaPhoneAlt,
} from "react-icons/fa";
import PageHeader from "../components/PageHeader";
import { Link } from "react-router-dom";
import ContactImage from "../images/contactimage.png";

function Contact() {
  return (
    <>
      <div className="w-full p-12 md:p-6">
        <div className="shadow-lg p-12 md:p-6">
          <PageHeader title="Contact Us" />

          <div className="flex sm:flex-col">
            <div className="mt-6 flex items-center justify-center relative contact-image rounded-md">
              <img
                src={ContactImage}
                alt="contactimage"
                className="w-full rounded-2xl opacity-70"
              />
              <div className="absolute top-2 right-50  flex justify-center gap-8">
                <FaMapMarkedAlt className="w-24 h-24 lg:w-16 lg:h-16 md:w-12 md:h-12 rounded-full bg-secondarycolor p-3 text-purewhite text-4xl md:text-2xl sm:text-lg font-bold" />{" "}
                <FaPhoneAlt className="w-24 h-24 lg:w-16 lg:h-16 md:w-12 md:h-12 rounded-full bg-secondarycolor p-3 text-purewhite text-4xl md:text-2xl sm:text-lg font-bold" />{" "}
                <FaAt className="w-24 h-24 lg:w-16 lg:h-16 md:w-12 md:h-12 rounded-full bg-secondarycolor p-3 text-purewhite text-4xl md:text-2xl sm:text-lg font-bold" />
              </div>
              {/* <div className="bg-black opacity-70 h-[50vh] md:h-[30vh] w-full rounded-2xl" />
              
              </div> */}
            </div>

            <div className="flex flex-col px-8 py-6 md:px-4 sm:px-2 mt-8">
              <div className="flex-1 grid grid-cols-8 flex-col gap-6">
                <div className="col-span-4 sm:col-span-8 shadow-md rounded-md p-4 space-y-2">
                  <h2 className="text-2xl md:text-sm font-bold uppercase underline underline-offset-4 text-secondarycolor">
                    Location:
                  </h2>
                  <div className="flex sm:flex-col justify-between">
                    <div>
                      <h2 className="font-bold uppercase">Kaduna</h2>
                      <p className="flex sm:flex-col gap-1 items-center">
                        <FaMapMarker className="text-maincolor" />: CB Finance
                        House, Kaduna,
                      </p>
                      <p> 16E Ahmadu Bello Way,</p>
                      <p>Kaduna State.</p>
                    </div>
                    {/* <div>
                    <h2 className="font-bold uppercase">Abuja</h2>
                    <p className="flex sm:flex-col gap-1 items-center">
                      <FaMapMarker className="text-maincolor" />: No. 35, Gana
                      Street,
                    </p>
                    <p>Maitama. Abuja.</p>
                  </div> */}
                  </div>
                </div>
                <div className="col-span-4 sm:col-span-8 shadow-md rounded-md p-4  space-y-2">
                  <h2 className="text-2xl md:text-sm font-bold uppercase underline underline-offset-4 text-secondarycolor">
                    Email:
                  </h2>
                  <p className="flex sm:flex-col gap-1 items-center">
                    <FaEnvelopeOpen className="text-maincolor" />:
                    info@janibaginger.com
                  </p>
                </div>
                <div className="col-span-4 sm:col-span-8 shadow-md rounded-md p-4 space-y-2">
                  <h2 className="text-2xl md:text-sm font-bold uppercase underline underline-offset-4 text-secondarycolor">
                    Phone:
                  </h2>
                  <p className="flex sm:flex-col gap-1 items-center">
                    <FaMobileAlt className="text-maincolor" />: +234 (818) 705
                    0789, +234 (908) 303 8664
                  </p>
                </div>
                <div className="col-span-4 sm:col-span-8 shadow-md rounded-md p-4 ">
                  <h2 className="mb-3">
                    For enquiries or/and Orders, Send us a mail using below
                    options:
                  </h2>
                  <div className="flex flex-col items-center space-y-4">
                    <Link
                      to={
                        "mailto:info@janibaginger.com?subject=General Enquiries!"
                      }
                      className="w-full text-center bg-gradient-to-r from-maincolor to-black text-sm text-whitesmokecolor hover:bg-gradient-to-l uppercase py-4 px-8 md:py-2 md:px-4 md:text-[11px] rounded-md transition-all duration-300 ease-in-out hover:bg-white"
                    >
                      General Enquiries
                    </Link>
                    <Link
                      to={
                        "mailto:info@janibaginger.com?subject=Order Placement!"
                      }
                      className="w-full text-center bg-gradient-to-r from-maincolor to-black text-sm text-whitesmokecolor hover:bg-gradient-to-l uppercase py-4 px-8 md:py-2 md:px-4 md:text-[11px] rounded-md transition-all duration-300 ease-in-out hover:bg-white"
                    >
                      Place Order
                    </Link>
                  </div>
                </div>
              </div>
              {/* <div className="flex-1">
              <form
                ref={formRef}
                onSubmit={handleSubmit}
                name="google-sheet"
                className="space-y-3"
              >
                <div className="">
                  <label htmlFor="name">Name</label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Your Name *"
                    className="w-full border-2 border-solid border-maincolor py-2 px-4 rounded-lg"
                  />
                </div>
                <div className="input-style">
                  <label htmlFor="name">Email</label>
                  <input
                    type="email"
                    name="email"
                    placeholder="Your Email *"
                    className="w-full border-2 border-solid border-maincolor py-2 px-4 rounded-lg"
                  />
                </div>
                <div className="input-style">
                  <label htmlFor="name">Phone No</label>
                  <input
                    type="text"
                    name="phone"
                    placeholder="Your Phone *"
                    className="w-full border-2 border-solid border-maincolor py-2 px-4 rounded-lg"
                  />
                </div>
                <div className="input-style">
                  <label htmlFor="name">Message</label>
                  <textarea
                    className="w-full border-2 border-solid border-maincolor py-2 px-4 rounded-lg"
                    placeholder="Your Message"
                  ></textarea>
                </div>
                <div className="input-style">
                  <input
                    type="submit"
                    value={loading ? "Loading..." : "SEND MESSAGE"}
                    className="bg-gradient-to-r from-maincolor to-black py-2 px-6 rounded-md text-purewhite hover:border hover:bg-gradient-to-l hover:bg-purewhite transition-all duration-300 ease-in-out"
                  />
                </div>
              </form>
            </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Contact;

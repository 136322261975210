import React from "react";
import { motion } from "framer-motion";
import SweetAd from "../../images/sweet-tooth-package.jpeg";

function SideAd() {
  return (
    <div className="w-full p-4 md:p-2">
      <img
        src={SweetAd}
        alt="ad"
        className="h-[500px] lg:h-[300px] sm:h-auto rounded-2xl w-full block"
      />
    </div>
  );
}

export default SideAd;

import React from "react";
import { motion } from "framer-motion";
import WhyOne from "../../images/ginger1.png";
import WhyTwo from "../../images/ginger2.png";
import WhyThree from "../../images/ginger3.png";
import SideAd from "../sidebar/SideAd";

function WhyChooseUS() {
  return (
    <div className="py-12 px-32 xl:px-24 lg:px-16 md:px12 sm:px-8 bg-purewhite min-h-screen flex lg:flex-col items-center justify-center gap-5">
      <div className="w-2/3 md:w-full ">
        <div className="text-center">
          <motion.h1
            className="mb-24 md:mb-12 text-secondarycolor text-4xl font-bold underline underline-offset-2 uppercase"
            initial={{ opacity: 0, y: -100 }}
            whileInView={{
              opacity: 1,
              y: 0,
              transition: { delay: 0.5, duration: 0.5 },
            }}
            viewport={{ once: true }}
          >
            Why Choose Us
          </motion.h1>
        </div>
        <div className="flex md:flex-col gap-8">
          <motion.div
            initial={{ opacity: 0, y: 200 }}
            whileInView={{
              opacity: 1,
              y: 0,
              transition: { delay: 0.2, duration: 0.5 },
            }}
            whileHover={{ scale: 1.1 }}
            viewport={{ once: true }}
            className="flex flex-col items-center text-center gap-5 p-8 transition-all duration-300 ease-in-out bg-gradient-to-b from-maincolor to-black text-whitesmokecolor rounded-md shadow-black shadow-lg"
          >
            <img
              className="p-2 border-none bg-whitesmokecolor rounded-full w-[150px]"
              src={WhyOne}
              alt="why-one"
            />
            <h2 className="text-lg  font-bold uppercase underline underline-offset-4">
              Quality
            </h2>
            <p className="sm:text-sm">
              Our ginger products are renowned for their superior flavor and
              aromatic potency.
            </p>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: 200 }}
            whileInView={{
              opacity: 1,
              y: 0,
              transition: { delay: 0.4, duration: 0.5 },
            }}
            whileHover={{ scale: 1.1 }}
            viewport={{ once: true }}
            className="flex flex-col items-center text-center gap-5 p-8 transition-all duration-300 ease-in-out bg-purewhite text-maincolor rounded-md shadow-black shadow-lg"
          >
            <img
              className="p-2 border-2 border-maincolor bg-whitesmokecolor rounded-full w-[150px]"
              src={WhyTwo}
              alt="why-two"
              width={150}
            />
            <h2 className="text-lg  font-bold uppercase underline underline-offset-4">
              Ethics
            </h2>
            <p className="sm:text-sm">
              We ensure fair trade practices, contributing to the well-being of
              our farmers.
            </p>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: 200 }}
            whileInView={{
              opacity: 1,
              y: 0,
              transition: { delay: 0.6, duration: 0.5 },
            }}
            whileHover={{ scale: 1.1 }}
            viewport={{ once: true }}
            className="flex flex-col items-center text-center gap-5 p-8 transition-all duration-300 ease-in-out bg-gradient-to-b from-maincolor to-black text-whitesmokecolor rounded-md shadow-black shadow-lg"
          >
            <img
              className="p-2 border-none bg-whitesmokecolor rounded-full w-[150px]"
              src={WhyThree}
              alt="why-three"
            />
            <h2 className="text-lg  font-bold uppercase underline underline-offset-4">
              Reliability
            </h2>
            <p className="sm:text-sm">
              We maintain a consistent supply chain, ensuring timely delivery
              and product availability.
            </p>
          </motion.div>
        </div>
      </div>
      <motion.div
        className="w-1/3  md:w-full border-2 border-maincolor rounded-md"
        initial={{ opacity: 0, y: 200 }}
        whileInView={{
          opacity: 1,
          y: 0,
          transition: { delay: 0.8, duration: 0.5 },
        }}
        viewport={{ once: true }}
      >
        <SideAd />
      </motion.div>
    </div>
  );
}

export default WhyChooseUS;

import React from 'react';
import ImageGallery from 'react-image-gallery';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-image-gallery/styles/css/image-gallery.css';
import 'react-tabs/style/react-tabs.css';
import PageHeader from '../components/PageHeader';

const clearing = [
  {
    original: 'https://i.ibb.co/DM9xLzb/clearing1.jpg',
    thumbnail: 'https://i.ibb.co/QF9qXmx/clearing1-thumbnail.jpg',
  },
  {
    original: 'https://i.ibb.co/rdBYwDf/clearing2.jpg',
    thumbnail: 'https://i.ibb.co/HYH7xtV/clearing2-thumbnail.jpg',
  },
  {
    original: 'https://i.ibb.co/8B7ZhCJ/clearing3.jpg',
    thumbnail: 'https://i.ibb.co/Dtm61ZV/clearing3-thumbnail.jpg',
  },
  {
    original: 'https://i.ibb.co/jfbBMt8/clearing4.jpg',
    thumbnail: 'https://i.ibb.co/5krSnsV/clearing4-thumbnail.jpg',
  },
  {
    original: 'https://i.ibb.co/ZfTSGVS/clearing5.jpg',
    thumbnail: 'https://i.ibb.co/1vtqwSf/clearing5-thumbnail.jpg',
  },
  {
    original: 'https://i.ibb.co/QvRLHsb/janiba1.jpg',
    thumbnail: 'https://i.ibb.co/wC3C0K3/janiba1-thumbnail.jpg',
  },
  {
    original: 'https://i.ibb.co/C50ZW61/janiba3.jpg',
    thumbnail: 'https://i.ibb.co/BTH3JLV/janiba3-thumbnail.jpg',
  },
  {
    original: 'https://i.ibb.co/XJRL75v/janiba4.jpg',
    thumbnail: 'https://i.ibb.co/c8TR7zX/janiba4-thumbnail.jpg',
  },
  {
    original: 'https://i.ibb.co/ssrGQFN/janiba6.jpg',
    thumbnail: 'https://i.ibb.co/zJTWvKY/janiba6-thumbnail.jpg',
  },
];

const atPort = [
  {
    original: 'https://i.ibb.co/mzZSTPg/janiba7.jpg',
    thumbnail: 'https://i.ibb.co/h1qNk6w/janiba7-thumbnail.jpg',
  },
  {
    original: 'https://i.ibb.co/ZdyJWBY/janiba8.jpg',
    thumbnail: 'https://i.ibb.co/gS8RxN6/janiba8-thumbnail.jpg',
  },
  {
    original: 'https://i.ibb.co/ZJxhY2x/janiba9.jpg',
    thumbnail: 'https://i.ibb.co/BfvThTz/janiba9-thumbnail.jpg',
  },
  {
    original: 'https://i.ibb.co/2hkV6rF/janiba10.jpg',
    thumbnail: 'https://i.ibb.co/dk1FKyZ/janiba10-thumbnail.jpg',
  },
  {
    original: 'https://i.ibb.co/T4h9NFw/janiba11.jpg',
    thumbnail: 'https://i.ibb.co/jL6bjqn/janiba11-thumbnail.jpg',
  },
  {
    original: 'https://i.ibb.co/FBtgkP1/janiba12.jpg',
    thumbnail: 'https://i.ibb.co/v3FtsBK/janiba12-thumbnail.jpg',
  },
  {
    original: 'https://i.ibb.co/6WkZ4kZ/janiba13.jpg',
    thumbnail: 'https://i.ibb.co/jVxGr5k/janiba13-thumbnail.jpg',
  },
];

const visit = [
  {
    original: 'https://i.ibb.co/8NH8BGj/janiba14.jpg',
    thumbnail: 'https://i.ibb.co/7rWRJMt/janiba14-thumbnail.jpg',
  },
  {
    original: 'https://i.ibb.co/W2KkKDf/janiba15.jpg',
    thumbnail: 'https://i.ibb.co/StXK1NW/janiba15-thumbnail.jpg',
  },
  {
    original: 'https://i.ibb.co/p0xpTdP/janiba16.jpg',
    thumbnail: 'https://i.ibb.co/FJt6rQF/janiba16-thumbnail.jpg',
  },
  {
    original: 'https://i.ibb.co/hcM12tm/janiba17.jpg',
    thumbnail: 'https://i.ibb.co/ZXhghw5/janiba17-thumbnail.jpg',
  },
  {
    original: 'https://i.ibb.co/7p3qtzV/janiba18.jpg',
    thumbnail: 'https://i.ibb.co/7SF2vWR/janiba18-thumbnail.jpg',
  },
  {
    original: 'https://i.ibb.co/Zx2RYWs/janiba19.jpg',
    thumbnail: 'https://i.ibb.co/vBw5t4r/janiba19-thumbnail.jpg',
  },
];

const stock = [
  {
    original: 'https://i.ibb.co/jDhmS4x/stock1.jpg',
    thumbnail: 'https://i.ibb.co/C9Rcvt5/stock1-thumbnail.jpg',
  },
  {
    original: 'https://i.ibb.co/jvyJx3G/stock2.jpg',
    thumbnail: 'https://i.ibb.co/QP1Nj1P/stock2-thumbnail.jpg',
  },
  {
    original: 'https://i.ibb.co/4776y69/stock3.jpg',
    thumbnail: 'https://i.ibb.co/zx3jQrX/stock3-thumbnail.jpg',
  },
  {
    original: 'https://i.ibb.co/vLMPQLp/stock4.jpg',
    thumbnail: 'https://i.ibb.co/6nzxxT1/stock4-thumbnail.jpg',
  },
  {
    original: 'https://i.ibb.co/hdDg4cF/stock5.jpg',
    thumbnail: 'https://i.ibb.co/L036fHn/stock5-thumbnail.jpg',
  },
  {
    original: 'https://i.ibb.co/rk7QNBj/janiba20.jpg',
    thumbnail: 'https://i.ibb.co/ssZV2pN/janiba20-thumbnail.jpg',
  },
  {
    original: 'https://i.ibb.co/sJ17NxS/janiba21.jpg',
    thumbnail: 'https://i.ibb.co/1fhxv52/janiba21-thumbnail.jpg',
  },
];

function Works() {
  return (
    <>
      <div className="w-full p-12 md:p-6">
        <div className="shadow-lg p-12 md:p-6">
          <PageHeader title="At Work" />

          <div className="px-32 lg:px-24 md:px-12 sm:px-6">
            <Tabs>
              <TabList>
                <Tab>At Cleaning Site</Tab>
                <Tab>At Dry Port</Tab>
                <Tab>On a Visit</Tab>
                <Tab>Stocks</Tab>
              </TabList>

              <TabPanel>
                <div className="bg-gradient-to-r from-gray to-purewhite p-4">
                  <h2 className="text-2xl font-bold text-center mb-4 underline underline-offset-4">
                    From Our Ginger Cleaning Site
                  </h2>
                  <ImageGallery items={clearing} />
                </div>
              </TabPanel>
              <TabPanel>
                <div className="bg-gradient-to-r from-gray to-purewhite p-4">
                  <h2 className="text-2xl font-bold text-center mb-4 underline underline-offset-4">
                    At the Kaduna Inland Dry Port Where Our Goods Go Through
                  </h2>
                  <ImageGallery items={atPort} />
                </div>
              </TabPanel>
              <TabPanel>
                <div className="bg-gradient-to-r from-gray to-purewhite p-4">
                  <h2 className="text-2xl font-bold text-center mb-4 underline underline-offset-4">
                    On a Visit to a Mechanised Cleaning, Drying and Processing
                    Plant with the President of the Ginger Association of
                    Nigeria
                  </h2>
                  <ImageGallery items={visit} />
                </div>
              </TabPanel>
              <TabPanel>
                <div className="bg-gradient-to-r from-gray to-purewhite p-4">
                  <h2 className="text-2xl font-bold text-center mb-4 underline underline-offset-4">
                    We always ensure to have enough stock for our valuable
                    customers
                  </h2>
                  <ImageGallery items={stock} />
                </div>
              </TabPanel>
            </Tabs>
          </div>
        </div>
      </div>
    </>
  );
}

export default Works;

import { Routes, Route, Navigate, Outlet } from 'react-router-dom';
import Layout from './components/Layout';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import Products from './pages/Products';
import Works from './pages/Works';
import Videos from './pages/Videos';

function App() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <Layout>
            <Outlet />
          </Layout>
        }
      >
        <Route index element={<Home />} />

        <Route path="about">
          <Route index element={<About />} />
        </Route>

        <Route path="works">
          <Route index element={<Works />} />
        </Route>

        <Route path="products">
          <Route index element={<Products />} />
        </Route>

        <Route path="videos">
          <Route index element={<Videos />} />
        </Route>

        <Route path="contact">
          <Route index element={<Contact />} />
        </Route>

        {/* Catch all page */}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Route>
    </Routes>
  );
}

export default App;

import React from "react";
import { motion } from "framer-motion";

function PageHeader({ title }) {
  return (
    <motion.h1
      className="text-center text-4xl md:text-3xl sm:text-2xl text-secondarycolor font-bold underline underline-offset-4 uppercase mb-6"
      initial={{ opacity: 0, y: -100 }}
      whileInView={{
        opacity: 1,
        y: 0,
        transition: { delay: 0.5, duration: 0.5 },
      }}
      viewport={{ once: true }}
    >
      {title}
    </motion.h1>
  );
}

export default PageHeader;

import React from "react";
import { motion } from "framer-motion";
import HomeAboutImage from "../../images/aboutImage.jpg";
import SidePriceList from "../sidebar/SIdePriceList";

function About() {
  return (
    <div className="about-us flex lg:flex-col gap-8 min-h-screen p-32 lg:p-24 md:p-16 sm:p-12 items-center justify-center bg-gradient-to-r from-maincolor to-black text-purewhite">
      <div className="flex lg:flex-col gap-8">
        <div className="w-1/3 lg:w-full">
          <div className="w-[400px] lg:w-full flex items-center justify-center rounded-2xl overflow-hidden border-2 border-purewhite p-2">
            <img
              src={HomeAboutImage}
              alt="aboutImage"
              className="h-[500px] lg:h-[400px] rounded-2xl w-full hover:scale-150 transition-all duration-300 ease-in-out"
            />
          </div>
        </div>
        <div className="w-1/3 lg:w-full space-y-6 p-4 text-md overflow-hidden text-lg">
          <motion.p
            initial={{ opacity: 0, x: 200 }}
            whileInView={{
              opacity: 1,
              x: 0,
              transition: { delay: 0.5, duration: 0.5 },
            }}
            viewport={{ once: true }}
          >
            <strong>Janiba Ginger Limited</strong> is an export-oriented company
            dedicated to providing premium dried sliced ginger, Hibiscus
            sabdariffa, and a healthy line of ginger spice fruit juices from
            Nigeria to discerning markets in Europe and the Americas. Locally,
            our products are celebrated for their unique qualities and versatile
            uses, while internationally, they cater to the growing demand for
            exotic and healthy food products.{" "}
          </motion.p>
          <motion.p
            initial={{ opacity: 0, x: 200 }}
            whileInView={{
              opacity: 1,
              x: 0,
              transition: { delay: 0.5, duration: 0.5 },
            }}
            viewport={{ once: true }}
          >
            Our organic and non-organically farmed ginger offers a unique
            flavour and aroma that sets it apart from competitors, emphasizing
            the authentic taste of Nigerian ginger.
          </motion.p>
          <motion.p
            initial={{ opacity: 0, x: 200 }}
            whileInView={{
              opacity: 1,
              x: 0,
              transition: { delay: 0.5, duration: 0.5 },
            }}
            viewport={{ once: true }}
          >
            We are confident in our ability to address challenges through
            quality control, regulatory compliance, efficient logistics, and
            strategic partnerships.{" "}
          </motion.p>
        </div>
        <motion.div
          className="w-1/3 lg:w-full border-2 border-purewhite rounded-md"
          initial={{ opacity: 0, y: 200 }}
          whileInView={{
            opacity: 1,
            y: 0,
            transition: { delay: 0.8, duration: 0.5 },
          }}
          viewport={{ once: true }}
        >
          <SidePriceList />
        </motion.div>
      </div>
    </div>
  );
}

export default About;

import React from "react";
import { motion } from "framer-motion";
import PageHeader from "../components/PageHeader";

const productsList = [
  {
    id: 1,
    title: "Ginger Powder",
    content:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum maxime provident dolor laudantium ipsa veniam sint, non placeat amet molestiae laborum id distinctio sed illum a libero odit aperiam in....",
    image: "https://i.ibb.co/0YktGqN/product-ginger.jpg",
    price: "",
  },
  {
    id: 2,
    title: "Ginger Garlic and Onions",
    content:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum maxime provident dolor laudantium ipsa veniam sint, non placeat amet molestiae laborum id distinctio sed illum a libero odit aperiam in....",
    image: "https://i.ibb.co/7nRZGDv/product-ginger-garlic.jpg",
    price: "",
  },
  {
    id: 3,
    title: "Tumeric Powder",
    content:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum maxime provident dolor laudantium ipsa veniam sint, non placeat amet molestiae laborum id distinctio sed illum a libero odit aperiam in....",
    image: "https://i.ibb.co/wwDM4nw/product-tumeric.jpg",
    price: "",
  },
  {
    id: 4,
    title: "Jolly Banana",
    content:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum maxime provident dolor laudantium ipsa veniam sint, non placeat amet molestiae laborum id distinctio sed illum a libero odit aperiam in....",
    image: "https://i.ibb.co/LpXLt3s/product-banana-juice.jpg",
    price: "2,500.00",
  },
  {
    id: 5,
    title: "Mellow Watermelon",
    content:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum maxime provident dolor laudantium ipsa veniam sint, non placeat amet molestiae laborum id distinctio sed illum a libero odit aperiam in....",
    image: "https://i.ibb.co/VmrB2Xt/product-watermelon.jpg",
    price: "1,300.00",
  },
  {
    id: 6,
    title: "Celery Drink",
    content:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum maxime provident dolor laudantium ipsa veniam sint, non placeat amet molestiae laborum id distinctio sed illum a libero odit aperiam in....",
    image: "https://i.ibb.co/S5txCxS/product-celery.jpg",
    price: "2,500.00",
  },
  {
    id: 7,
    title: "Pineapple Ginger",
    content:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum maxime provident dolor laudantium ipsa veniam sint, non placeat amet molestiae laborum id distinctio sed illum a libero odit aperiam in....",
    image: "https://i.ibb.co/CvsmhwF/product-pineapple.jpg",
    price: "1,800.00",
  },
  {
    id: 8,
    title: "Beetroot Rouge",
    content:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum maxime provident dolor laudantium ipsa veniam sint, non placeat amet molestiae laborum id distinctio sed illum a libero odit aperiam in....",
    image: "https://i.ibb.co/cC5vGzG/product-beetroot.jpg",
    price: "4,000.00",
  },
  {
    id: 9,
    title: "Brownies",
    content:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum maxime provident dolor laudantium ipsa veniam sint, non placeat amet molestiae laborum id distinctio sed illum a libero odit aperiam in....",
    image: "https://i.ibb.co/L0dLCsy/product-brownies.jpg",
    price: "",
  },
  {
    id: 10,
    title: "Cupcakes and cookies",
    content:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum maxime provident dolor laudantium ipsa veniam sint, non placeat amet molestiae laborum id distinctio sed illum a libero odit aperiam in....",
    image: "https://i.ibb.co/GtBNGjf/product-cupcakes-cookies.jpg",
    price: "500.00",
  },
];

function Products() {
  return (
    <>
      <div className="w-full p-12 md:p-6">
        <div className="shadow-lg p-12 md:p-6">
          <PageHeader title="Our Products" />
          <div className="grid grid-cols-9 lg:grid-cols-8 lg:gap-8 gap-16 px-32 lg:px-12 sm:px-8 text-center">
            {productsList.map((item, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 100 }}
                whileInView={{
                  opacity: 1,
                  y: 0,
                  transition: { delay: 0.5, duration: 0.5 },
                }}
                viewport={{ once: true }}
                className="col-span-3 lg:col-span-4 sm:col-span-8 space-y-4 shadow-lg py-3"
              >
                <div className="relative">
                  <img
                    src={item.image}
                    alt="products"
                    className="w-full rounded-tl-2xl rounded-tr-2xl"
                  />
                  {item.price && (
                    <p className="absolute top-2 right-3 bg-secondarycolor text-purewhite font-bold p-1 rounded-md">
                      N{item.price}
                    </p>
                  )}
                </div>
                <div className="p-2">
                  <h2 className="text-md text-darkgray">{item.title}</h2>
                </div>
                {/* 
                <div>
                  <Link href={"/contact"}>
                    <button className="border-none border-solid bg-gradient-to-r from-maincolor to-black py-2 px-6 rounded-md text-purewhite hover:border hover:bg-gradient-to-l hover:bg-purewhite transition-all duration-300 ease-in-out">
                      Place Order
                    </button>
                  </Link>
                </div> */}
              </motion.div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default Products;

import React, { useState } from "react";
import { motion } from "framer-motion";
import Logo from "../../images/janiba-logo-png.png";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { FaMobileAlt, FaEnvelopeOpen } from "react-icons/fa";

const CustomLink = ({ href, title, className = "" }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;

  return (
    <Link to={href} className={`${className} relative group`}>
      {title}
      <span
        className={`h-[2px] inline-block  bg-gradient-to-r from-secondarycolor to-black absolute left-0 -bottom-0.5 group-hover:w-full transition-[width] ease duration-500 ${
          currentPath === href ? "w-full" : "w-0"
        }`}
      >
        &nbsp;
      </span>
    </Link>
  );
};

const CustomMobileLink = ({ href, title, className = "", toggle }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;

  const handleClick = () => {
    toggle();
    navigate(href);
  };
  return (
    <button
      href={href}
      className={`${className} relative group uppercase`}
      onClick={handleClick}
    >
      {title}
      <span
        className={`h-[2px] inline-block  bg-gradient-to-r from-maincolor to-black absolute left-0 -bottom-0.5 group-hover:w-full transition-[width] ease duration-500 ${
          currentPath === href ? "w-full" : "w-0"
        }`}
      >
        &nbsp;
      </span>
    </button>
  );
};

function Nav() {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };
  return (
    <nav>
      <div className="bg-gradient-to-r from-maincolor to-black text-whitesmokecolor font-thin text-sm flex items-center justify-between px-48 py-2 xl:px-24 lg:px-16 md:px12 sm:px-8 md:text-xs sm:text-[10px]">
        <div className="flex gap-1 items-center">
          <FaMobileAlt />: +234 (818) 705 0789
        </div>
        <div className="sm:hidden">RC No: 2020935</div>
        <div className="flex gap-1 items-center">
          <FaEnvelopeOpen />: info@janibaginger.com
        </div>
      </div>
      <div className="flex justify-between items-center px-48 py-8 relative xl:px-24 lg:px-16 md:px12 sm:px-8">
        <Link to={"/"}>
          <img src={Logo} alt="Logo" className="w-[200px]" />
        </Link>
        <div className="space-x-5 text-gray-400 uppercase lg:hidden">
          <CustomLink href={"/"} title="Home">
            Home
          </CustomLink>
          <CustomLink href={"/about"} title="About">
            About us
          </CustomLink>
          <CustomLink href={"/products"} title="Products">
            Our Products
          </CustomLink>
          <CustomLink href={"/works"} title="At Work">
            Work Done
          </CustomLink>
          <CustomLink href={"/videos"} title="Videos">
            Videos
          </CustomLink>
          <CustomLink href={"/contact"} title="Contact">
            Contact us
          </CustomLink>
        </div>

        {isOpen ? (
          <motion.div
            className="min-w-[70vw] flex flex-col justify-center items-center z-30 space-y-3 bg-maincolor/90 rounded-lg backdrop-blur-md py-32 px-12 text-purewhite fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 "
            initial={{ scale: 0, opacity: 0, x: "-50%", y: "-50%" }}
            animate={{ scale: 1, opacity: 1, transition: { duration: 1 } }}
          >
            <CustomMobileLink href={"/"} title="Home" toggle={handleClick}>
              Home
            </CustomMobileLink>
            <CustomMobileLink
              href={"/about"}
              title="About"
              toggle={handleClick}
            >
              About us
            </CustomMobileLink>
            <CustomMobileLink
              href={"/products"}
              title="Products"
              toggle={handleClick}
            >
              Our Products
            </CustomMobileLink>
            <CustomMobileLink
              href={"/works"}
              title="At Work"
              toggle={handleClick}
            >
              Work Done
            </CustomMobileLink>
            <CustomMobileLink
              href={"/videos"}
              title="Videos"
              toggle={handleClick}
            >
              Videos
            </CustomMobileLink>
            <CustomMobileLink
              href={"/contact"}
              title="Contact"
              toggle={handleClick}
            >
              Contact us
            </CustomMobileLink>
          </motion.div>
        ) : null}

        <button
          className=" absolute right-8 top-12 flex-col justify-center items-center hidden lg:flex"
          onClick={handleClick}
        >
          <span
            className={`bg-maincolor block transition-all duration-300 ease-out h-0.5 w-6 rounded-sm ${
              isOpen ? "rotate-45 translate-y-1" : " -translate-y-0.5"
            }`}
          ></span>
          <span
            className={`bg-maincolor block transition-all duration-300 ease-out h-0.5 w-6 rounded-sm my-0.5 ${
              isOpen ? "opacity-0" : "opacity-100"
            }`}
          ></span>
          <span
            className={`bg-maincolor block transition-all duration-300 ease-out h-0.5 w-6 rounded-sm ${
              isOpen ? "-rotate-45 -translate-y-1" : "translate-y-0.5"
            }`}
          ></span>
        </button>
      </div>
    </nav>
  );
}

export default Nav;
